import { UserLogin, GetWhiteList } from '@/api/gy/base/sysuser'
import LocalStorage from 'quasar/src/plugins/LocalStorage.js';
import msg from "@/config/notify"
// const ticket = 2 * 60 * 60 * 1000 // cookie时效
//当前系统登录用户信息及常规操作
export const appUser = {
    namespaced: true,
    state: {
        //登录用户Token
        //获取用户登录后获得的token
        // 1、第一次登录的时候，前端调后端的登陆接口，发送用户名和密码
        // 2、后端收到请求，验证用户名和密码，验证成功，就给前端返回一个token
        // 3、前端拿到token，将token存储到localStorage和vuex中，并跳转路由页面
        // 4、前端每次跳转路由，就判断 localStroage 中有无 token ，没有就跳转到登录页面，有则跳转到对应路由页面
        // 5、每次调后端接口，都要在请求头中加token
        // 6、后端判断请求头中有无token，有token，就拿到token并验证token，验证成功就返回数据，验证失败（例如：token过期）就返回401，请求头中没有token也返回401
        // 7、如果前端拿到状态码为401，就清除token信息并跳转到登录页面
        Token: "",
        //用户ID
        UserID: "",
        //编码
        UserCode: "",
        //用户姓名
        UserName: "",
        //性别
        Sex: '',
        //电话
        Phone: "",
        //邮箱
        Email: "",
        //当前登录的机构ID
        HospID: "",
        //系统授权功能菜单
        AuthModules: null,
        //系统授权的机构列表
        AuthHosps: null,
        //当前系统路由ID
        CurrentSysID: 0,
        //当前选中路由
        CurrentModuleID: "",
        //白名单
        WhiteList: "",
        //图片地址/API地址
        ImagesUrl: "https://putimenzhen.com/",
        //ImagesUrl: "https://api.qdgzzgyy.com/",
        //ImagesUrl: "http://192.168.1.188:8088/",
    },
    getters: {
        ImagesUrl: state => {
            if (state.ImagesUrl) {
                return state.ImagesUrl
            } else {
                return ""
            }
        },
        currentUser: (state) => {
            if (state.UserCode) return state
            const user = JSON.parse(LocalStorage.getItem("CurrentUser"))
            return user
        },
        token: state => {
            if (state.Token) return state.Token
            const token = LocalStorage.getItem('ACCESS_TOKEN')
            if (token) {
                return token
            }
            return null
        },
        //用户ID
        userID: state => {
            if (state.UserID) return state.UserID
            const userID = LocalStorage.getItem('userID')
            if (userID) return userID
            return null
        },
        //用户姓名
        userName: state => {
            if (state.UserName) return state.UserName
            const un = LocalStorage.getItem('userName')
            if (un) {
                return un
            }
            return ''
        },
        //当前机构ID
        hospID: state => {
            if (state.HospID) return state.HospID
            const hosp = LocalStorage.getItem('HospID')
            if (hosp) {
                return hosp
            }
            return null
        },
        //获取授权
        authModules: state => {
            if (state.authModules && state.authModules.length > 0) return state.authModules
            const authModules = JSON.parse(LocalStorage.getItem('authModules'))
            if (authModules) {
                return authModules
            }
            return null
        },
        //获取机构授权
        authHosps: state => {
            if (state.AuthHosps) return state.AuthHosps
            const hosp = JSON.parse(LocalStorage.getItem('Hosps'))
            if (hosp) {
                return hosp
            }
            return null
        },
        //当前系统ID
        currentSysID: (state) => {
            if (state.CurrentSysID) return parseInt(state.CurrentSysID)
            let sys = LocalStorage.getItem("CurrentSys")
            if (sys) {
                return parseInt(sys)
            }
            // const authModules = JSON.parse(LocalStorage.getItem('authModules'))

            // if (authModules.length > 0)
            //     return authModules[0].SysID
            // else
            return 0
        },
        //当前选中的功能
        currentModuleID: (state) => {
            if (state.CurrentModuleID) return parseInt(state.CurrentModuleID)
            let mod = LocalStorage.getItem("CurrentModule")
            if (mod) return parseInt(mod)
            return 0
        },
        //当前选中的机构
        currentHosp: (state) => {
            var hosp = state.HospID
            if (!hosp)
                hosp = LocalStorage.getItem('HospID')

            const hospList = JSON.parse(LocalStorage.getItem('Hosps'))
            var current = null
            if (hosp && hospList.length > 0) {
                current = hospList.find(v => {
                    return v.HospID == hosp
                })
            } else {
                if (hospList && hospList.length > 0)
                    current = hospList[0]
            }
            return current
        },
        //系统白名单
        whiteList: (state) => {
            var white = state.WhiteList
            if (!white)
                white = JSON.parse(LocalStorage.getItem("WhiteList"))
            return white
        }
    },
    mutations: {
        //设置当前登录用户的信息
        setAppUser(state, userInfo) {
            state.UserID = userInfo.UserID
            state.UserCode = userInfo.UserCode
            state.UserName = userInfo.UserName
            state.Sex = userInfo.Sex
            state.Phone = userInfo.Phone
            state.Email = userInfo.Email
            state.AuthModules = userInfo.Systems
            state.AuthHosps = userInfo.Hosps
            state.HospID = userInfo.DefaultHospID
            LocalStorage.set('authModules', JSON.stringify(userInfo.Systems))
            LocalStorage.set('userID', userInfo.UserID)
            LocalStorage.set('userName', userInfo.UserName)
            LocalStorage.set('Hosps', JSON.stringify(userInfo.Hosps))
            LocalStorage.set('HospID', userInfo.DefaultHospID)
            LocalStorage.set('CurrentUser', JSON.stringify(state))
        },
        setToken(state, _token) {
            state.token = _token
            LocalStorage.set('ACCESS_TOKEN', _token)
        },
        //清理state关键数据
        clearState(state) {
            state.UserID = null
            state.UserName = null
            state.AuthModules = null
            state.AuthHosps = null
            state.HospID = null

            LocalStorage.clear()

        },
        setCurrentSysID(state, sysid) {
            state.CurrentSysID = sysid
            LocalStorage.set('CurrentSys', sysid)
        },
        setCurrentModuleID(state, currentModule) {
            state.CurrentModuleID = currentModule
            LocalStorage.set('CurrentModule', currentModule)
        },
        setCurrentHospID(state, currentHospID) {
            state.HospID = currentHospID
            LocalStorage.set('HospID', currentHospID)
        },
        setWhiteList(state, list) {
            state.WhiteList = list
            LocalStorage.set("WhiteList", JSON.stringify(list))
        }
    },
    actions: {
        //用户登录
        actUserLogin({ commit }, payload) {
            return new Promise((resolve, reject) => {
                UserLogin(payload)
                    .then(res => {
                        if (!res.Code) {
                            commit('setAppUser', res.Data)
                            commit('setToken', res.OtherData)
                            resolve(res)
                        }
                        else {
                            msg.error(res.Message)
                            reject(res)
                        }

                    })
                    .catch(error => {
                        msg.error(error.Message)
                        reject(error)
                    })
                    .finally(() => {

                    })
            })
        },
        //用户注销
        actUserLogout({ commit }) {
            commit('clearState')
        },
        //修改密码
        actUserChangePassword() {

        },
        //设置当前选中的子系统
        actSetSysID({ commit }, sysid) {
            commit('setCurrentSysID', sysid)
        },
        //设置当前选中的模块
        actSetCurrentModuleID({ commit }, module) {
            commit('setCurrentModuleID', module)
        },
        //设置当前选中的机构
        actSetCurrentHospID({ commit }, hid) {
            commit('setCurrentHospID', hid)
        },
        actGetWhiteList({ commit }) {
            return new Promise((resolve) => {
                GetWhiteList().then(res => {
                    if (res.Code == 0) {
                        commit("setWhiteList", res.Data)
                        resolve(res.Data)
                    }else{
                        resolve(res)
                    }
                })
            })

        }
    }
}