
export const getters = {
    //最大页面高度 38*2为header + footer高度
    maxPageHeight: state => state.height - 37 * 2,

    maxPageWidth: state => {
        return state.width
    },

    //获取系统静态设置参数
    appSettings: state => {
        return state.appSetting
    },
    //当前登录用户信息
    currentUser: state => {
        return state.appUser
    },

    //当前登录的机构信息
    currentHosp: state => {
        return state.appUser.currentHosp
    }
}