import Notify from 'quasar/src/plugins/Notify.js';
const timeout = '2000'
const pos = "top"

Notify.setDefaults({
    color: 'primary',
    position:pos,
    timeout,
    textColor: 'white',
    classes: '',
})

const optSuccess = {
    type: "positive",
    color: "teal-1",
    position:pos,
    textColor: "positive",
    timeout 
}

//success 消息
const success = function (s, options) {
    var opt = Object.assign(optSuccess, options,{message:s})
    Notify.create(opt)
}

const optError = {
    type: "negative",
    color: "white",
    position:"top-right",
    textColor: "negative",
    timeout:3000
}
//error 消息
const error = function (s, options) {
    var opt = Object.assign(optError, options,{message:s})
    Notify.create(opt)
}

const optInfo = {
    type: "info",
    color: "teal-1",
    position:pos,
    textColor: "primary",
    timeout
}
//info 消息
const info = function (s, options) {
    var opt = Object.assign(optInfo, options,{message:s})
    Notify.create(opt)
}

const optWarning = {
    type:"warning",
    color: "teal-1",
    position:pos,
    textColor: "orange",
    timeout
}
//warning 消息
const warning = function (s, options) {
    var opt = Object.assign(optWarning, options,{message:s})
    Notify.create(opt)
}

export default {
    success,
    error,
    info,
    warning
}