/*------------------------------------------------
// File Name:tGY_Base_Link.js
// File Description:tGY_Base_Link store方法
// Author:zxl
// Create Time:2022/09/02 13:55:33
//------------------------------------------------*/

import {
    GettGY_Base_LinkList,
    GettGY_Base_LinkListByPage,
    GettGY_Base_LinkEntity,
    CreatetGY_Base_LinkEntity,
    UpdatetGY_Base_LinkEntity,
    DeletetGY_Base_LinkEntity,
} from '@/api/gy/base/link'

import msg from '@/config/notify'
import dlg from '@/config/dialog'
//import { toRaw } from "vue";

export const TgyBaseLink = {
    namespaced: true,
    state: {
        TgyBaseLinkList: [],
        TgyBaseLinkListLoading: false,
        TgyBaseLinkEntity: null,
        TgyBaseLinkShowDlg: false,
    },
    mutations: {
        setTgyBaseLinkList(state, dataList) {
            state.TgyBaseLinkList = [...dataList]
        },
        setTgyBaseLinkListLoading(state, visible) {
            state.TgyBaseLinkListLoading = visible
        },
        setTgyBaseLinkShowDlg(state, visible) {
            state.TgyBaseLinkShowDlg = visible
        },
        setTgyBaseLinkEntity(state, entity) {
            state.TgyBaseLinkEntity = entity
        },
    },
    actions: {
        //创建外部链接对象
        actCreateTgyBaseLink({ commit }, parm) {
            return new Promise((resolve, reject) => {
                CreatetGY_Base_LinkEntity(parm).then(res => {
                    if (res.Code == 0) {
                        commit('setTgyBaseLinkEntity', res.Data)
                        commit('setTgyBaseLinkShowDlg', true)
                        resolve(res)
                    } else {
                        reject(res)
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        },
        //加载外部链接对象
        actGetTgyBaseLinkEntity({ commit }, parm) {
            return new Promise((resolve) => {
                commit('setTgyBaseLinkListLoading', true)
                GettGY_Base_LinkEntity(parm).then(res => {
                    if (res.Code == 0) {
                        commit('setTgyBaseLinkList', res.Data)
                        resolve(res)
                    } else {
                        //dlg.alert(res.Message)
                        msg.error(res.Message)
                    }
                }).finally(() => {
                    commit('setTgyBaseLinkListLoading', false)
                })
            }).catch(err => {
                console.log(err)
            })
        },
        //加载外部链接列表
        actGetTgyBaseLinkList({ commit }, parm) {
            return new Promise((resolve) => {
                commit('setTgyBaseLinkListLoading', true)
                GettGY_Base_LinkList(parm).then(res => {
                    if (res.Code == 0) {
                        commit('setTgyBaseLinkList', res.Data)
                        resolve(res)
                    } else {
                        msg.error(res.Message)
                    }
                }).finally(() => {
                    commit('setTgyBaseLinkListLoading', false)
                })
            }).catch(err => {
                console.log(err)
            })
        },
        //分页加载外部链接列表
        actGetTgyBaseLinkListByPage({ commit }, parm) {
            return new Promise((resolve) => {
                commit('setTgyBaseLinkListLoading', true)
                GettGY_Base_LinkListByPage(parm).then(res => {
                    if (res.Code == 0) {
                        commit('setTgyBaseLinkList', res.Data)
                        resolve(res)
                    } else {
                        msg.error(res.Message)
                    }
                }).finally(() => {
                    commit('setTgyBaseLinkListLoading', false)
                })
            }).catch(err => {
                console.log(err)
            })
        },
        //保存外部链接信息
        actUpdateTgyBaseLink({ commit }, parm) {
            return new Promise((resolve) => {
                UpdatetGY_Base_LinkEntity(parm).then(res => {
                    if (res.Code == 0) {
                        commit('setTgyBaseLinkShowDlg', false)
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        },
        //编辑tGY_Base_Link
        actEditTgyBaseLinkData({ commit }, entity) {
            return new Promise((resolve) => {
                let parm = {                    
                    TgyBaseLinkID : entity.TgyBaseLinkID
                }
                GettGY_Base_LinkEntity(parm).then(res => {
                    if (res.Code == 0) {
                        // var info = {}
                        // Object.assign(info,state.getTableNameL3(TableName.Name)%>Entity)
                        // Object.assign(info,res.Data)
                        // commit('getTableNameL3(TableName.Name)%>Entity', info)
                        commit('setTgyBaseLinkEntity', res.Data)
                        commit('setTgyBaseLinkShowDlg', true)
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        },
        //编辑tGY_Base_Link ,传对象过来
        actEditTgyBaseLink({ commit }, entity) {
            return new Promise(() => {
                commit('setTgyBaseLinkEntity', entity)
                commit('setTgyBaseLinkShowDlg', true)
            }).catch(err => {
                console.log(err)
            })
        },
        //删除tGY_Base_Link
        actDeleteTgyBaseLink({ commit }, e) {
            return new Promise((resolve) => {
                dlg.confirm("是否确定要删除当前外部链接？").onOk(() => {
                    DeletetGY_Base_LinkEntity(e).then(res => {
                        if (res.Code == 0) {
                            commit('setTgyBaseLinkShowDlg', false)
                           
                            msg.success("删除外部链接成功。")
                            resolve(res)
                        } else {
                            msg.success("删除外部链接失败，原因:" + res.Message)
                            resolve(res)
                        }
                    })
                })
            }).catch(err => {
                console.log(err)
            })
        }
    }
}


