import {
    GettGY_Base_RoleList,
    CreatetGY_Base_RoleEntity,
    UpdatetGY_Base_RoleEntity,
    DeletetGY_Base_RoleEntity
} from '@/api/gy/base/sysrole'
import { toRaw } from '@vue/reactivity'

//系统角色管理
export const SysRole = {
    namespaced: true,

    state: {
        ///角色列表
        RoleList: [],
        ///当前操作角色对象
        RoleEntity: {},
        ///编辑弹窗
        ShowDlg: false,
        ///加载状态
        Loading: false,
    },
    mutations: {
        setRoleList(state, list) {
            state.RoleList = [...list]
        },
        setRoleEntity(state, entity) {
            state.RoleEntity = entity
        },
        setShowDlg(state, visible) {
            state.ShowDlg = visible
        },
        setLoading(state, visible) {
            state.Loading = visible
        }
    },
    actions: {
        //加载列表数据
        actLoadData({ commit }, parm) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                GettGY_Base_RoleList(parm).then(res => {
                    if (res.Code == 0) {
                        commit('setRoleList', res.Data)
                        resolve(res)
                    } else {
                        reject(res)
                    }
                }).catch(err => {
                    reject(err)
                }).finally(() => {
                    commit('setLoading', false)
                })
            })
        },
        actCreateRoleEntity({ commit }) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                CreatetGY_Base_RoleEntity({}).then(res => {
                    if (res.Code == 0) {
                        commit("setRoleEntity", res.Data)
                        commit("setShowDlg", true)
                        resolve(res)
                    } else {
                        reject(res)
                    }
                }).catch(err => {
                    reject(err)
                }).finally(() => {
                    commit('setLoading', false)
                })
            })
        },
        //设置当前编辑的角色对象
        actSetRoleEntity({ commit }, entity) {
            commit('setRoleEntity', entity)
        },
        //编辑角色信息
        actEditRoleEntity({ commit }, entity) {
            commit('setRoleEntity', entity)
            commit('setShowDlg', true)
        },
        //保存角色对象
        actUpdateRoleEntity({ commit, state }, roleInfo) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)

                var obj = {}
                Object.assign(obj, state.RoleEntity, roleInfo)
                UpdatetGY_Base_RoleEntity(obj).then(res => {
                    if (res.Code == 0) {
                        let list = toRaw(state.RoleList)
                        let find = list.find(v => {
                            return v.RoleID == res.Data.RoleID
                        })
                        if (find) {
                            Object.assign(find, res.Data)
                        } else {
                            list.push(res.Data)
                        }
                        commit('setRoleEntity', obj)
                        commit('setRoleList', list)
                        commit('setShowDlg', false)
                        resolve(res)
                    }
                    else {
                        reject(res)
                    }
                }).catch(err => {
                    reject(err)
                }).finally(() => {
                    commit('setLoading', false)
                })
            })
        },
        //删除角色对象
        actDeleteRoleEntity({ commit, state }, roleInfo) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                DeletetGY_Base_RoleEntity(roleInfo).then(res => {
                    if (res.Code == 0) {
                        let list = toRaw(state.RoleList)
                        let idx = list.findIndex(v => { return v.RoleID == roleInfo.RoleID })
                        if (idx >= 0) {
                            list.splice(idx, 1)
                            commit('setRoleList', list)
                        }
                        resolve(res)
                    } else {
                        reject(res)
                    }
                }).catch(err => {
                    reject(err)
                }).finally(() => {
                    commit('setLoading', false)
                })
            })
        }
    }
}