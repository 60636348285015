import {
    GettGY_Base_LogListByPage,
    GettGY_Base_LogEntity,
    DeletetGY_Base_LogAll,
    DeletetGY_Base_LogCollection
} from '@/api/gy/base/log'

import msg from '@/config/notify'
import dlg from '@/config/dialog'

export const SysLog = {
    namespaced: true,
    state: {
        Loading: false,
        LogList: [],
        LogEntity: null,
    },
    mutations: {
        setLoading(state, visible) {
            state.Loading = visible
        },
        setLogList(state, list) {
            state.LogList = [...list]
        },
        setLogEntity(state, entity) {
            state.LogEntity = entity
        }
    },
    actions: {
        //加载日志列表
        actLoadLogList({ commit }, parm) {
            return new Promise((resolve) => {
                commit('setLoading', true)
                GettGY_Base_LogListByPage(parm)
                    .then(res => {
                        if (res.Code == 0) {
                            commit('setLogList', res.Data)
                            resolve(res)
                        } else {
                            msg.error(res.Message)
                        }
                    })
                    .catch(err => {
                        msg.error(err.Message || "请求方法异常")
                    })
                    .finally(() => {
                        commit('setLoading', false)
                    })
            })
        },
        //读取一条日志
        actLoadLogEntity({ commit }, parm) {
            return new Promise((resolve) => {
                commit('setLoading', true)
                GettGY_Base_LogEntity(parm)
                    .then(res => {
                        if (res.Code == 0) {
                            commit('setLogEntity', res.Data)
                            resolve(res)
                        } else {
                            msg.error(res.Message)
                        }
                    })
                    .catch(err => {
                        msg.error(err || "请求方法异常")
                    })
                    .finally(() => {
                        commit('setLoading', false)
                    })
            })
        },
        //清空所有日志
        actDeleteLogAll({ commit }, parm) {
            dlg
                .confirm("是否确定要清空数据库所有日志(*注:该操作清空的数据不能还原)")
                .onOk(() => {
                    return new Promise((resolve, reject) => {
                        commit('setLoading', true)
                        DeletetGY_Base_LogAll(parm).then(res => {
                            if (res.Code == 0) {
                                msg.success("日志记录已清空。")
                                resolve(res)
                            } else {
                                msg.error(res.Message)
                                reject(res)
                            }
                        }).catch(err => {
                            msg.error(err || "请求方法异常")
                            reject(err)
                        }).finally(() => {
                            commit('setLoading', false)
                        })
                    })
                })
        },
        //删除部分日志数据
        actDeleteLogCollection({ commit }, parm) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                DeletetGY_Base_LogCollection(parm).then(res => {
                    if (res.Code == 0) {
                        msg.success("日志记录已清空。")
                        resolve(res)
                    } else {
                        msg.error(res.Message)
                        reject(res)
                    }
                }).catch(err => {
                    msg.error(err || "请求方法异常")
                    reject(err)
                }).finally(() => {
                    commit('setLoading', false)
                })
            })
        }

    },

}