import { axios } from '@/utils/request' //'../utils/request'

const api = {
    gyhospuser: '/tGY_Base_HospUser'
}

export default api

export function GettGY_Base_HospUserListByHospID(parameter) {
    return axios({
        url: api.gyhospuser + '/GettGY_Base_HospUserListByHospID',
        method: 'post',
        data: parameter
    })
}

export function GettGY_Base_HospUserListByUserID(parameter) {
    return axios({
        url: api.gyhospuser + '/GettGY_Base_HospUserListByUserID',
        method: 'post',
        data: parameter
    })
}

export function UpdatetGY_Base_HospUserListByHospID(parameter) {
    return axios({
        url: api.gyhospuser + '/UpdatetGY_Base_HospUserListByHospID',
        method: 'post',
        data: parameter
    })
}

export function UpdatetGY_Base_HospUserListByUserID(parameter) {
    return axios({
        url: api.gyhospuser + '/UpdatetGY_Base_HospUserListByUserID',
        method: 'post',
        data: parameter
    })
}


export function AddtGY_Base_HospUserEntity(parameter) {
    return axios({
        url: api.gyhospuser + '/AddtGY_Base_HospUserEntity',
        method: 'post',
        data: parameter
    })
}

export function DeletetGY_Base_HospUserEntity(parameter) {
    return axios({
        url: api.gyhospuser + '/DeletetGY_Base_HospUserEntity',
        method: 'post',
        data: parameter
    })
}