import {
    GettGY_Base_SystemTreeList
} from "@/api/gy/base/system"

import {
    GettGY_Base_SystemListByRoleID,
    UpdatetGY_Base_SystemListByRoleID
} from '@/api/gy/base/sysrolemodules'

import msg from "@/config/notify"
import { toRaw } from "@vue/reactivity"

export const SysRoleModules = {
    namespaced: true,
    state: {
        //系统模块树
        SystemTree: [],
        //授权的功能模块
        RoleModules: [],
        Loading: false,
    },
    mutations: {
        setRoleModules(state, modules) {
            state.RoleModules = [...modules]
        },
        setLoading(state, visible) {
            state.Loading = visible
        },
        setSystemTree(state, data) {
            state.SystemTree = [...data]
        }
    },
    actions: {
        //加载系统权限树
        actLoadSystemTree({ commit }) {
            GettGY_Base_SystemTreeList().then(res => {
                if (res.Code == 0) {
                    commit('setSystemTree', res.Data)
                } else {
                    msg.error(res.Message)
                }
            }).catch(err => {
                msg.error(err)
            }).finally(() => {
                commit('setLoading', false)
            })
        },
        //加载角色已授权的列表
        actLoadRoleModules({ commit }, parm) {
            commit('setLoading', true)
            GettGY_Base_SystemListByRoleID(parm).then(res => {
                if (res.Code == 0) {
                    commit('setRoleModules', res.Data)
                } else {
                    msg.error(res.Message)
                }
            }).catch(err => {
                msg.error(err)
            }).finally(() => {
                commit('setLoading', false)
            })
        },
        //设置授权的功能模块数组
        actSetRoleModules({ commit }, modules) {
            commit('setRoleModules', modules)
        },
        //保存角色授权的模块数组
        actUpdateRoleModules({ commit, state }, roleID) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                let list = toRaw(state.RoleModules)
                UpdatetGY_Base_SystemListByRoleID({ RoleID: roleID, SelectedModuleIDs: list }).then(res => {
                    if (res.Code == 0) {
                        msg.success("保存角色授权成功")
                        resolve(res)
                    } else {
                        msg.error(res.Message)
                        reject(res)
                    }
                }).catch(err => {
                    msg.error(err)
                    reject(err)
                }).finally(() => {
                    commit('setLoading', false)
                })
            })

        }
    }
}