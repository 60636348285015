/*------------------------------------------------
// File Name:tGY_Base_Link.js
// File Description:tGY_Base_Link API方法js接口
// Author:zxl
// Create Time:2022/09/02 13:55:33
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _baseLink: "/tGY_Base_Link"
}

export default api

//-------------------创建外部链接对象-------------------
export function CreatetGY_Base_LinkEntity(parameter) {
    return axios({
        url: api._baseLink + "/CreatetGY_Base_LinkEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取外部链接列表-------------------
export function GettGY_Base_LinkList(parameter) {
    return axios({
        url: api._baseLink + "/GettGY_Base_LinkList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取外部链接列表---------------
export function GettGY_Base_LinkListByPage(parameter) {
    return axios({
        url: api._baseLink + "/GettGY_Base_LinkListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取外部链接对象---------------
export function GettGY_Base_LinkEntity(parameter) {
    return axios({
        url: api._baseLink + "/GettGY_Base_LinkEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存外部链接对象---------------
export function UpdatetGY_Base_LinkEntity(parameter) {
    return axios({
        url: api._baseLink+ "/UpdatetGY_Base_LinkEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉外部链接对象---------------
export function DeletetGY_Base_LinkEntity(parameter) {
    return axios({
        url: api._baseLink + "/DeletetGY_Base_LinkEntity",
        method: 'post',
        data: parameter
    })
}
