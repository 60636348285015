import baseConfig from '@/config/baseConfig'
import { getBrowerInfo,GetOs } from '@/utils/explorer'
import LocalStorage from 'quasar/src/plugins/LocalStorage.js';

export const appSetting = {
    namespaced: true,
    state: {
        //配置请求WebApi的后端地址
        ApiUrl: 'http://192.168.1.190:8089/api/',
        //app的标题名称 登录框上方
        AppTitle: "",
        //网站项目名称一般指的是软件的产品名
        AppName: "",
        //网站的Logo
        AppLogo: "",
        //项目名称产品针对甲方的项目命名
        ProjName: "",
        //软件提供商名称
        AppSupplier: "",
        //版权信息
        CopyRight: "2021",
        //程序版本号
        AppVerison: "",
        //os 版本
        ClientOS:"",
        // 浏览器版本
        ClientBrowser:"",
        //多页模式
        MultiPage:false,
        //左侧边栏状态
        LeftDrawer:null

    },
    mutations: {
        setAppSetting(state, parm) {
            state.ApiUrl = parm.ApiUrl
            state.AppTitle = parm.AppTitle
            state.AppLogo = parm.AppLogo
            state.AppName = parm.AppName
            state.AppLogo = parm.AppLogo
            state.ProjName = parm.ProjName
            state.AppSupplier = parm.AppSupplier
            state.CopyRight = parm.CopyRight
            state.AppVerison = parm.AppVerison

            state.ClientBrowser = getBrowerInfo()
            state.ClientOS = GetOs()
        },
        setMultiPage(state,enable){
            state.MultiPage = enable
            LocalStorage.set("MultiPage",enable)
        },
        setLeftDrawer(state,visible){
            state.LeftDrawer = visible
            LocalStorage.set('LeftDrawer',visible)
        }
    },
    getters:{
        multiPage(state){
            var enable = state.MultiPage 
            if(LocalStorage.has("MultiPage")){
                enable = LocalStorage.getItem("MultiPage")
                return enable
            }
            
            return enable
        },
        leftDrawer(state){
            var visible = state.LeftDrawer 
            if(LocalStorage.has("LeftDrawer")){
                visible = LocalStorage.getItem("LeftDrawer")
                return visible
            }
            return visible
        }
    },
    actions: {
        //加载系统设置
        actLoadSetting({ commit }) {

            var enable = false
            if(LocalStorage.has("MultiPage")){
                enable = LocalStorage.getItem("MultiPage")
            }

            commit('setMultiPage',enable)

            commit('setAppSetting', baseConfig)

        }
    },
}