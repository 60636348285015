import {
    GettGY_Base_RoleUserListByRoleID,
    GettGY_Base_RoleUserListByUserID,
    UpdatetGY_Base_RoleUserListByUserID,
    UpdatetGY_Base_RoleUserEntity,
    DeletetGY_Base_RoleUserEntity
} from '@/api/gy/base/sysroleuser'
import { toRaw } from '@vue/reactivity'
import msg from '@/config/notify'
import dlg from '@/config/dialog'

export const SysRoleUser = {
    namespaced: true,
    state: {
        //角色用户列表
        RoleUserList: [],
        Loading: false,
    },
    mutations: {
        setUserList(state, list) {
            state.RoleUserList = [...list]
        },
        setLoading(state, visible) {
            state.Loading = visible
        }
    },
    actions: {
        //加载角色用户列表 按角色ID加载用户列表
        actLoadUserList({ commit }, parm) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                GettGY_Base_RoleUserListByRoleID(parm).then(res => {
                    if (res.Code == 0) {
                        commit('setUserList', res.Data)
                        resolve(res)
                    } else {
                        msg.error(res.Message)
                        reject(res)
                    }
                }).catch(err => {
                    msg.error(err)
                    reject(err)
                }).finally(() => {
                    commit('setLoading', false)
                })
            })
        },
        //按用户ID加载角色列表
        actLoadRoleList({ commit }, parm) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                GettGY_Base_RoleUserListByUserID(parm).then(res => {
                    if (res.Code == 0) {
                        commit('setUserList', res.Data)
                        resolve(res)
                    } else {
                        msg.error(res.Message)
                        reject(res)
                    }
                }).catch(err => {
                    msg.error(err)
                    reject(err)
                }).finally(() => {
                    commit('setLoading', false)
                })
            })
        },
        //安用户ID 保存角色列表
        actUpdateRoleListByUserID({commit},parm){
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                UpdatetGY_Base_RoleUserListByUserID(parm).then(res => {
                    if (res.Code == 0) {
                        msg.success("保存用户授权角色成功！")
                        resolve(res)
                    } else {
                        msg.error(res.Message)
                        reject(res)
                    }
                }).catch(err => {
                    msg.error(err)
                    reject(err)
                }).finally(() => {
                    commit('setLoading', false)
                })
            })
        },
        //添加用户
        actAddUser({ commit, state }, user) {
            return new Promise((resolve, reject) => {
                var list = toRaw(state.RoleUserList)
                var idx = list.findIndex(v => v.UserID == user.UserID)
                if (idx >= 0) {
                    msg.info('用户已存在不需要添加')
                    resolve()
                    return
                }
                commit('setLoading', true)
                UpdatetGY_Base_RoleUserEntity(user).then(res => {
                    if (res.Code == 0) {
                        list.push(res.Data)
                        commit('setUserList', list)
                        resolve(res)
                    } else {
                        msg.error(res.Message)
                        reject(res)
                    }
                }).catch(err => {
                    msg.error(err)
                    reject(err)
                }).finally(() => {
                    commit('setLoading', false)
                })
            })
        },
        //移除用户
        actRemoveUser({ commit, state }, user) {
            dlg.confirm("是否确定要从角色中移除当前用户？")
                .onOk(() => {
                    return new Promise((resolve, reject) => {
                        commit('setLoading', true)
                        DeletetGY_Base_RoleUserEntity(user).then(res => {
                            if (res.Code == 0) {
                                var list = toRaw(state.RoleUserList)
                                var idx = list.findIndex(v => v.RoleID == user.RoleID && v.UserID == user.UserID)
                                if (idx >= 0) {
                                    list.splice(idx, 1)
                                }
                                commit('setUserList', list)
                                resolve(res)
                            } else {
                                msg.error(res.Message)
                                reject(res)
                            }
                        }).catch(err => {
                            msg.error(err)
                            reject(err)
                        }).finally(() => {
                            commit('setLoading', false)
                        })
                    })
                })
        }
    }
}