
import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import zhCN from './config/i18n/zhCN'

import LoadingBar from 'quasar/src/plugins/LoadingBar.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import Meta from 'quasar/src/plugins/Meta.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';

// To be used on app.use(Quasar, { ... })

export default {

  config: {
    notify: {
      position: 'top',
      timeout: 2500,
      textColor: 'white',
      color: 'primary',
      actions: [{ icon: 'close', }]
    },
    loadingBar: {
      skipHijack: true,
      position: 'bottom',
      color: "primary",
      size: "3px"
    },
  },
  plugins: {
    LocalStorage,
    LoadingBar,
    Notify,
    Dialog,
    Loading,
    Meta,
  },
  lang:zhCN
}
