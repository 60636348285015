import { axios } from '@/utils/request' //'../utils/request'

const api = {
    gyrolemodules: '/tGY_Base_RoleModules'
}

export default api

export function GettGY_Base_SystemListByRoleID(parameter) {
    return axios({
        url: api.gyrolemodules + '/GettGY_Base_SystemListByRoleID',
        method: 'post',
        data: parameter
    })
}

export function UpdatetGY_Base_SystemListByRoleID(parameter) {
    return axios({
        url: api.gyrolemodules + '/UpdatetGY_Base_SystemListByRoleID',
        method: 'post',
        data: parameter
    })
}


