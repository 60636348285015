import axios from "axios";
import { VueAxios } from "axios";

const service = axios.create({
  //baseURL: "https://putimenzhen.com/api",
  baseURL: "https://api.qdgzzgyy.com/api",
  //baseURL: "http://192.168.1.188:8088/api",
  //baseURL: "/api",
  timeout: 1000 * 60  * 5, // request timeout
});

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service);
  },
};

export { installer as VueAxios, service as axios };
