
import { axios } from '@/utils/request' //'../utils/request'

const api = {
    gylog: '/tGY_Base_Log'
}

export default api

export function GettGY_Base_LogListByPage(parameter) {
    return axios({
        url: api.gylog + '/GettGY_Base_LogListByPage',
        method: 'post',
        data: parameter
    })
}


export function GettGY_Base_LogEntity(parameter) {
    return axios({
        url: api.gylog + '/GettGY_Base_LogEntity',
        method: 'post',
        data: parameter
    }).catch(err => {
        console.log(err)
    })
}


export function DeletetGY_Base_LogCollection(parameter) {
    return axios({
        url: api.gylog + '/DeletetGY_Base_LogCollection',
        method: 'post',
        data: parameter
    })
}


export function DeletetGY_Base_LogAll(parameter) {
    return axios({
        url: api.gylog + '/DeletetGY_Base_LogAll',
        method: 'post',
        data: parameter
    })
}