import { axios } from '@/utils/request' //'../utils/request'

const api = {
    gyuser: '/tGY_Base_SysUser'
}

export default api

export function UserLogin(parameter) {
    return axios({
        url: api.gyuser + '/UserLogin',
        method: 'post',
        data: parameter
    })
}

//获取新用户对象
export function CreatetGY_Base_SysUserEntity(parameter) {
    return axios({
        url: api.gyuser + '/CreatetGY_Base_SysUserEntity',
        method: 'post',
        data: parameter
    })
}

///获取用户对象
export function GettGY_Base_SysUserEntity(parameter) {
    return axios({
        url: api.gyuser + '/GettGY_Base_SysUserEntity',
        method: 'post',
        data: parameter
    })
}

//获取用户列表
export function GettGY_Base_SysUserList(parameter) {
    return axios({
        url: api.gyuser + '/GettGY_Base_SysUserList',
        method: 'post',
        data: parameter
    })
}

//删除用户
export function DeletetGY_Base_SysUserEntity(parameter) {
    return axios({
        url: api.gyuser + '/DeletetGY_Base_SysUserEntity',
        method: 'post',
        data: parameter
    })
}

//保存更新用户
export function UpdatetGY_Base_SysUserEntity(parameter) {
    return axios({
        url: api.gyuser + '/UpdatetGY_Base_SysUserEntity',
        method: 'post',
        data: parameter
    })
}
//重置登录密码
export function UserResetPassword(parameter) {
    return axios({
        url: api.gyuser + '/UserResetPassword',
        method: 'post',
        data: parameter
    })
}
//获取系统白名单
export function GetWhiteList() {
    return axios({
        url: api.gyuser + '/GetWhiteList',
        method: 'post',
    })
}
//修改密码
export function UserChangePassword(parameter) {
    return axios({
        url: api.gyuser + '/UserChangePassword',
        method: 'post',
        data: parameter
    })
}

//修改用户启用/禁用
export function UpateIsValid(parameter) {
    return axios({
        url: api.gyuser + '/UpateIsValid',
        method: 'post',
        data: parameter
    })
}