
import { mapGetters } from 'vuex'
import Dialog from 'quasar/src/plugins/Dialog.js';

const setting = mapGetters(['appSetting'])

const alterOpt = {
    title: setting.AppName || "系统提示",
    position: "standard",
    onCancel:null,
    html:true,
}
const alert = function (msg,option) {
    var opt = Object.assign(alterOpt, option, { message: msg })
    console.log('option=====>',opt)
    return Dialog.create(opt)

}


const confirmOpt = {
    title: setting.AppName || "系统提示",
    position: "standard",
    cancel: true,
    persistent: true,
    html:true,
}
const confirm = function (msg, option) {
    var opt = Object.assign(confirmOpt, option, { message: msg })
    return Dialog.create(opt)
}

export default {
    alert,
    confirm
}