import { axios } from '@/utils/request' //'../utils/request'

const api = {
    gyroleuser: '/tGY_Base_RoleUser'
}

export default api

export function GettGY_Base_RoleUserListByRoleID(parameter) {
    return axios({
        url: api.gyroleuser + '/GettGY_Base_RoleUserListByRoleID',
        method: 'post',
        data: parameter
    })
}

export function GettGY_Base_RoleUserListByUserID(parameter) {
    return axios({
        url: api.gyroleuser + '/GettGY_Base_RoleUserListByUserID',
        method: 'post',
        data: parameter
    })
}

export function UpdatetGY_Base_RoleUserListByUserID(parameter) {
    return axios({
        url: api.gyroleuser + '/UpdatetGY_Base_RoleUserListByUserID',
        method: 'post',
        data: parameter
    })
}

export function UpdatetGY_Base_RoleUserEntity(parameter) {
    return axios({
        url: api.gyroleuser + '/UpdatetGY_Base_RoleUserEntity',
        method: 'post',
        data: parameter
    })
}

export function DeletetGY_Base_RoleUserEntity(parameter) {
    return axios({
        url: api.gyroleuser + '/DeletetGY_Base_RoleUserEntity',
        method: 'post',
        data: parameter
    })
}

