import { nextTick } from "vue"

//多页模式管理模块
export const MultiPageBar = {
    namespaced: true,
    state: {
        //打开的页面列表
        PageList: [],
        //当前的活动页标签
        CurrentPage: null,
    },
    mutations: {
        setPageList(state, data) {
            state.PageList = [...data]
        },
        setCurrentPage(state, data) {
            state.CurrentPage = data
        }
    },
    getters: {
        currentRoute(state) {
            var route = state.PageList.find(v => v.name == state.CurrentPage)
            if (route)
                return route.path
            else
                return ""
        }
    },
    actions: {
        //打开路由页面
        actOpenPage({ commit, state }, page) {
            var routes = [...state.PageList]
            var find = routes.find(v => v.name == page.name)
            if (find) {
                commit('setCurrentPage', find.name)
            } else {
                routes.push(page)
                commit('setPageList', routes)
            }
        },
        actClosePage({ commit, state }, page) {
            return new Promise(resolve => {
                var routes = [...state.PageList]
                var idx = routes.findIndex(v => v.name == page.name)
                if (idx >= 0) {
                    if (routes.length > 1) {
                        var cname = ''
                        var c = null
                        if (state.CurrentPage == page.name) {
                            if (idx == 0)
                                c = routes[1]
                            else if (idx == routes.length - 1) {
                                c = routes[idx - 1]
                            }
                            else {
                                c = routes[idx - 1]
                            }
                            cname = c.name
                            commit('setCurrentPage', cname)
                            nextTick(() => {
                                routes.splice(idx, 1)
                                commit('setPageList', routes)
                            })
                            resolve(c)
                        } else {
                            if (idx == 0) {
                                c = routes[0]
                            } else if (idx == routes.length - 1) {
                                c = routes[idx - 1]
                            } else {
                                c = routes[idx]
                            }
                            cname = c.name
                            routes.splice(idx, 1)
                            nextTick(() => {
                                commit('setPageList', routes)
                            })
                            resolve()
                        }
                    } else {
                        resolve()
                    }
                } else {
                    resolve()
                }
            })
        },
        actClearPages({ commit, state }) {
            return new Promise(resolve => {
                var routes = [...state.PageList]
                var cur = routes.findIndex(v => v.name == state.CurrentPage)
                
                if (cur>=0) {
                    var list = routes.splice(cur,1)
                    commit('setPageList', list)
                }
                resolve()
            })
        }
    }
}
