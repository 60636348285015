import {
    GettGY_Base_HospUserListByHospID,
    GettGY_Base_HospUserListByUserID,
    UpdatetGY_Base_HospUserListByUserID,
    AddtGY_Base_HospUserEntity,
    DeletetGY_Base_HospUserEntity
} from '@/api/gy/base/hospuser'

import msg from "@/config/notify"
import { toRaw } from '@vue/reactivity'
//机构用户
export const SysHospUser = {
    namespaced: true,
    state: {
        //加载状态
        Loading: false,
        //机构用户列表
        HospUserList: [],
        //选中的用户ID列表
        SelectedUserIDs: [],
        //选中的机构ID列表
        SelectedHospIDs: []
    },
    mutations: {
        setHospUserList(state, list) {
            state.HospUserList = [...list]
        },
        setLoading(state, visible) {
            state.Loading = visible
        },
        setSelectedUserIDs(state, ids) {
            state.SelectedUserIDs = [...ids]
        },
        setSelectedHospIDs(state, ids) {
            state.SelectedHospIDs = [...ids]
        }
    },
    actions: {
        //按机构ID加载机构用户列表
        actLoadHospUserListByHospID({ commit }, parm) {
            return new Promise((resolve, reject) => {
                commit('setLoading', false)
                GettGY_Base_HospUserListByHospID(parm)
                    .then(res => {
                        if (res.Code == 0) {
                            commit('setHospUserList', res.Data)
                            resolve(res)
                        } else {
                            msg.error(res.Message)
                            reject(res)
                        }
                    }).catch(err => {
                        msg.error(err)
                        reject(err)
                    }).finally(() => {
                        commit('setLoading', false)
                    })
            })
        },
        //按用户ID加载结构列表
        actLoadHospUserListByUserID({ commit }, parm) {
            return new Promise((resolve, reject) => {
                commit('setLoading', false)
                GettGY_Base_HospUserListByUserID(parm)
                    .then(res => {
                        if (res.Code == 0) {
                            let selectedHospIDs = res.Data.map(v=>v.HospID)
                            commit('setHospUserList', res.Data)
                            commit('setSelectedHospIDs',selectedHospIDs)
                            resolve(res)
                        } else {
                            msg.error(res.Message)
                            reject(res)
                        }
                    }).catch(err => {
                        msg.error(err)
                        reject(err)
                    }).finally(() => {
                        commit('setLoading', false)
                    })
            })
        },
        
        //根据用户ID保存选中的机构列表 参数:{UserID:'',HospIDs:[...]}
        actUpdateHospUserListByUserID({ commit }, parm) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                UpdatetGY_Base_HospUserListByUserID(parm).then(res => {
                    if (res.Code == 0) {
                        msg.success('保存授权机构成功')
                        resolve(res)
                    } else {
                        msg.error(res.Message)
                        reject(res)
                    }
                }).catch(err => {
                    msg.error(err)
                    reject(err)
                }).finally(() => {
                    commit('setLoading', false)
                })
            })
        },
        //添加机构用户对象
        actAddHospUserEntity({ commit, state }, parm) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                AddtGY_Base_HospUserEntity(parm).then(res => {
                    if (res.Code == 0) {
                        let list = toRaw(state.HospUserList)
                        list.push(res.Data)
                        commit('setHospUserList', list)
                        resolve(res)
                    } else {
                        msg.error(res.Message)
                        reject(res)
                    }
                }).catch(err => {
                    msg.error(err)
                    reject(err)
                }).finally(() => {
                    commit('setLoading', false)
                })
            })
        },
        //删除机构用户对象
        actDeleteHospUserEntity({ commit, state }, parm) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                DeletetGY_Base_HospUserEntity(parm).then(res => {
                    if (res.Code == 0) {
                        let list = toRaw(state.HospUserList)
                        let idx = list.findIndex(v => { return v.HospID == parm.HospID && v.UserID == parm.UserID })
                        if (idx >= 0) {
                            list.splice(idx, 1)
                            commit('setHospUserList', list)
                            msg.success("移除机构用户成功")
                            resolve(res)
                        } else {
                            msg.warning("您的页面数据可能以过期，请刷新收重试")
                            reject(res)
                        }
                    }
                }).catch(err=>{
                    msg.error(err)
                    reject(err)
                }).finally(()=>{
                    commit('setLoading',false)
                })
            })
        }
    }
}