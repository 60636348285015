import {
    GettGY_Base_HospBasicInfoTreeList,
    GettGY_Base_HospBasicInfoList
} from '@/api/gy/base/hosp'

import msg from '@/config/notify'
//机构维护功能
export const SysHosp ={
    namespaced:true,
    state:{
        Loading:false,
        ///机构树
        HospTree:[],
        //机构列表
        HospList:[],
    },
    mutations:{
        setLoading(state,visible){
            state.Loading = visible
        },
        setHospTree(state,data){
            state.HospTree = [...data]
        },
        setHospList(state,data){
            state.HospList = [...data]
        },
    },
    actions:{
        //加载机构树
        actLoadHospTree({commit},parm){
            return new Promise((resolve,reject)=>{
                commit('setLoading',true)
                GettGY_Base_HospBasicInfoTreeList(parm).then(res=>{
                    if(res.Code == 0){
                        commit('setHospTree',res.Data)
                        resolve(res)
                    }else{
                        msg.error(res.Message)
                        reject(res)
                    }
                }).catch(err=>{
                    msg.error(err)
                    reject(err)
                }).finally(()=>{
                    commit('setLoading',false)
                })
            })
        },

        //加载机构列表
        actLoadHospList({commit},parm){
            return new Promise((resolve,reject)=>{
                commit('setLoading',true)
                GettGY_Base_HospBasicInfoList(parm).then(res=>{
                    if(res.Code == 0){
                        commit('setHospList',res.Data)
                        resolve(res)
                    }else{
                        msg.error(res.Message)
                        reject(res)
                    }
                }).catch(err=>{
                    msg.error(err)
                    reject(err)
                }).finally(()=>{
                    commit('setLoading',false)
                })
            })
        },
    }
}