import { axios } from '@/utils/request' //'../utils/request'

const api = {
    gysystem: '/tGY_Base_System'
}

export default api

export function GettGY_Base_SystemTreeList(parameter) {
    return axios({
        url: api.gysystem + '/GettGY_Base_SystemTreeList',
        method: 'post',
        data: parameter
    })
}

