import { axios } from '@/utils/request' //'../utils/request'

const api = {
    gyhosp: '/tGY_Base_HospBasicInfo'
}

export default api

export function CreatetGY_Base_HospBasicInfoEntity(parameter) {
    return axios({
        url: api.gyhosp + '/CreatetGY_Base_HospBasicInfoEntity',
        method: 'post',
        data: parameter
    })
}


export function GettGY_Base_HospBasicInfoEntity(parameter) {
    return axios({
        url: api.gyhosp + '/GettGY_Base_HospBasicInfoEntity',
        method: 'post',
        data: parameter
    })
}

export function GettGY_Base_HospBasicInfoTreeList(parameter) {
    return axios({
        url: api.gyhosp + '/GettGY_Base_HospBasicInfoTreeList',
        method: 'post',
        data: parameter
    })
}

export function UpdatetGY_Base_HospBasicInfoEntity(parameter) {
    return axios({
        url: api.gyhosp + '/UpdatetGY_Base_HospBasicInfoEntity',
        method: 'post',
        data: parameter
    })
}


export function DeletetGY_Base_HospBasicInfoEntity(parameter) {
    return axios({
        url: api.gyhosp + '/DeletetGY_Base_HospBasicInfoEntity',
        method: 'post',
        data: parameter
    })
}

//-------------------获取机构列表--大后台用-------------------
export function GettGY_Base_HospBasicInfoListData(parameter) {
    return axios({
        url: api.gyhosp + "/GettGY_Base_HospBasicInfoListData",
        method: 'post',
        data: parameter
    })
}

export function GettGY_Base_HospBasicInfoList(parameter) {
    return axios({
        url: api.gyhosp + "/GettGY_Base_HospBasicInfoList",
        method: 'post',
        data: parameter
    })
}
