/*
 * 获取IE浏览器版本
 */
function getIeVersion() {
    let IEMode = document.documentMode
    let rMsie = /(msie\s|trident.*rv:)([\w.]+)/;
    let ma = window.navigator.userAgent.toLowerCase()
    let match = rMsie.exec(ma);
    try {
        return match[2];
    } catch (e) {
        return IEMode;
    }
}

/*
 * 获取oper浏览器版本
 */
function getOperaVersion(userAgent) {
    try {
        if (window.opera) {
            return userAgent.match(/opera.([\d.]+)/)[1];
        } else if (userAgent.indexOf("opr") > 0) {
            return userAgent.match(/opr\/([\d.]+)/)[1];
        }
    } catch (e) {
        return 0;
    }
}

/*
 * 判断是否为360浏览器
 */
function validate360(option, value) {
    let mimeTypes = window.navigator.mimeTypes
    for (let mt in mimeTypes) {
        if (mimeTypes[mt][option] == value) {
            return true;
        }
    }
    return false;
}


/*
 * 获取浏览器版本信息
 */
export function getBrowerInfo() {
    let userAgent = window.navigator.userAgent.toLowerCase()
    let browserType = ''
    let browserVersion = ''
    // let isIE = userAgent.match(/msie/) != null || userAgent.match(/trident/) != null
    // 浏览器类型-IE
    if (userAgent.match(/msie/) != null || userAgent.match(/trident/) != null) {
        browserType = 'IE'
        browserVersion = getIeVersion()
    }
    // 欧朋
    else if (window.opera || (userAgent.indexOf("opr") > 0)) {
        browserType = 'Opera'
        browserVersion = getOperaVersion(userAgent)
    }
    // UC
    else if (userAgent.indexOf("ubrowser") > 0) {
        browserType = 'UC'
        browserVersion = userAgent.match(/ubrowser\/([\d.]+)/)[1]
    }
    // 百度
    else if (userAgent.indexOf("bidubrowser") > 0) {
        browserType = 'Baidu'
        browserVersion = userAgent.match(/bidubrowser\/([\d.]+)/)[1]
    }
    // 搜狗
    else if (userAgent.indexOf("metasr") > 0 || userAgent.indexOf("se 2.x") > 0) {
        browserType = 'Sogo'
        // browserVersion =  userAgent.match(/metasr\/([\d.]+)/)[1]
    }
    // QQ
    else if (userAgent.indexOf("tencenttraveler") > 0) {
        browserType = 'QQ'
        browserVersion = userAgent.match(/tencenttraveler\/([\d.]+)/)[1]
    }
    // QQ
    else if (userAgent.indexOf("qqbrowser") > 0) {
        browserType = 'QQ'
        browserVersion = userAgent.match(/qqbrowser\/([\d.]+)/)[1]
    }
    // 遨游
    else if (userAgent.indexOf("maxthon") > 0) {
        browserType = '遨游'
        browserVersion = userAgent.match(/maxthon\/([\d.]+)/)[1]
    }
    // 火狐
    else if (userAgent.indexOf("firefox") > 0) {
        browserType = 'FireFox'
        browserVersion = userAgent.match(/firefox\/([\d.]+)/)[1]
    }
    // edge
    else if (userAgent.indexOf("edge") > 0) {
        browserType = 'Edge'
        browserVersion = userAgent.match(/edge\/([\d.]+)/)[1]
    }
    // 谷歌/360
    else if (userAgent.indexOf("chrome") > 0) {
        if (validate360('type', 'application/x360mmplugin')) {
            browserType = '360'
            // browserVersion =  userAgent.match(/chrome\/([\d.]+)/)[1]
        } else {
            browserType = 'Google Chrome'
            browserVersion = userAgent.match(/chrome\/([\d.]+)/)[1]
        }
    }
    // 苹果
    else if (userAgent.indexOf("Safari") > -1) {
        browserType = 'Safari'
        browserVersion = userAgent.match(/version\/([\d.]+)/)[1]
    }
    return browserType + ' ' + browserVersion
}

// get os
export function GetOs() {
    let sUserAgent = window.navigator.userAgent.toLowerCase()
    let platform = window.navigator.platform.toLowerCase()
    let isWin = (platform === 'win32') || (platform === 'windows')
    let isMac = (platform === 'mac68k') || (platform === 'macppc') || (platform === 'macintosh') || (platform === 'macintel')
    if (isMac) return 'Mac'
    var isUnix = (platform === 'x11') && !isWin && !isMac
    if (isUnix) return 'Unix'
    var isLinux = (String(platform).indexOf('linux') > -1)
    if (isLinux) return 'Linux'
    if (isWin) {
        var isWin2K = sUserAgent.indexOf('windows nt 5.0') > -1 || sUserAgent.indexOf('windows 2000') > -1
        if (isWin2K) return 'Win2000'
        var isWinXP = sUserAgent.indexOf('windows nt 5.1') > -1 || sUserAgent.indexOf('windows xp') > -1
        if (isWinXP) return 'WinXP'
        var isWin2003 = sUserAgent.indexOf('windows nt 5.2') > -1 || sUserAgent.indexOf('windows 2003') > -1
        if (isWin2003) return 'Win2003'
        var isWinVista = sUserAgent.indexOf('windows nt 6.0') > -1 || sUserAgent.indexOf('windows vista') > -1
        if (isWinVista) return 'WinVista'
        var isWin7 = sUserAgent.indexOf('windows nt 6.1') > -1 || sUserAgent.indexOf('windows 7') > -1
        if (isWin7) return 'Win7'
        var isWin10 = sUserAgent.indexOf('windows nt 10') > -1 || sUserAgent.indexOf('windows 10') > -1
        if (isWin10) return 'Win10'
    }
    if (sUserAgent.indexOf('android') > -1) return 'Android'
    if (sUserAgent.indexOf('iphone') > -1) return 'iPhone'
    if (sUserAgent.indexOf('symbianos') > -1) return 'SymbianOS'
    if (sUserAgent.indexOf('windows phone') > -1) return 'Windows Phone'
    if (sUserAgent.indexOf('ipad') > -1) return 'iPad'
    if (sUserAgent.indexOf('ipod') > -1) return 'iPod'
    return 'others'
}
