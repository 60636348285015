<template>
    <q-layout>
        <q-page-container>
            <q-page class="flex flex-center body-bkg">
                <q-card style="">
                    <q-card-section horizontal>
                        <img src="../../assets/login_img.png" />
                        <q-card-section style="min-width:550px">
                            <div class="q-ma-xl">
                                <h3 class="app-title q-mt-xl">{{ appSettings.AppName }}</h3>
                                <span class="text-bold">版本号：{{ appSettings.AppVerison }}</span>
                            </div>
                            <form @submit.prevent="simulateSubmit">
                                <div class="q-gutter-lg q-pa-xl row">
                                    <q-input class="col-12" ref="userNameRef" filled v-model="username" label="用户名"
                                        :rules="nameRules" lazy-rules />

                                    <q-input class="col-12" ref="passwordRef" filled type="password" v-model="password"
                                        label="密码" lazy-rules />

                                    <q-btn class="col-12" size="lg" icon="fas fa-key" :loading="loading" label="登录"
                                        color="primary" type="submit" @click="onSubmit" />
                                </div>
                            </form>

                            <div class="q-ma-xl row">
                                <div class="col">
                                    <div class="row app-corpinfo q-mt-md">{{ appSettings.AppSupplier }}</div>
                                    <div class="row app-corpinfo">联系电话:0532-88898988</div>
                                </div>
                                <div class="col-2">
                                    <img style="height:100px;width:100px" src="../../assets/login_qrcode.png" />
                                </div>
                            </div>
                        </q-card-section>
                    </q-card-section>
                </q-card>
                <q-toolbar>
                    <q-space />
                    <div>{{ appSettings.CopyRight }} {{ appSettings.AppSupplier }} <a href="https://beian.miit.gov.cn">鲁ICP备2023010138号-1</a></div>
                    <q-space />
                </q-toolbar>
            </q-page>
        </q-page-container>
    </q-layout>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import { useStore } from "vuex";
import msg from "@/config/notify";

export default {
    computed: {
        ...mapGetters(["appSettings"]),
    },
    setup() {
        const userNameRef = ref(null);
        const passwordRef = ref(null);
        const username = ref("");
        const password = ref("");
        const store = useStore();
        const loading = ref(false);
        const loginSuccess = () => {
            // 延迟 1 秒显示欢迎信息
            setTimeout(() => {
                msg.success("欢迎回来");
                window.location = "/#/home"
            }, 1000);
        };
        return {
            username,
            password,
            nameRules: [(val) => (val && val.length > 0) || "请输入用户名"],
            passwordRules: [(val) => (val && val.length > 0) || "请输入密码"],
            passwordRef,
            userNameRef,
            loading,
            onSubmit: () => {
                userNameRef.value.validate();
                passwordRef.value.validate();
                if (
                    !userNameRef.value.hasError &&
                    !passwordRef.value.hasError
                ) {
                    loading.value = true;
                    setTimeout(() => {
                        store
                            .dispatch("appUser/actUserLogin", {
                                UserCode: username.value,
                                Password: password.value,
                            })
                            .then((res) => {
                                loginSuccess(res);
                                loading.value = false;
                            })
                            .catch(() => {
                                loading.value = false;
                            })
                            .finally(() => {
                                loading.value = false;
                            });
                    }, 1000);
                }
            },
        };
    },
    methods: {},
};
</script>

<style scoped>
.bg-image {
    background-image: linear-gradient(135deg, #38343f 0%, #4882da 100%);
}

.app-title {
    line-height: 38px;
    font-size: 38px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0071E7;
    text-shadow: 0px 5px 6px rgba(27, 137, 252, 0.08);

    background: linear-gradient(180deg, #7BBCFE 20.21484375%, #0C63FF 91.455078125%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.app-corpinfo {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    line-height: 28px;
}

.body-bkg {
    background-image: url('../../assets/login_bg.jpg');
    background-color: #cccccc;
}
</style>
