// 定义配置变量
let baseConfig

let syncGetJsonData = function (url) {
  let xhr = new XMLHttpRequest()
  xhr.open('get', url, false)
  xhr.send()
  return JSON.parse(xhr.responseText)
}

baseConfig = syncGetJsonData('/config.json?r=' + Math.random()) // 随机参数用于防止取缓存

export default baseConfig