//用户管理
import {
    CreatetGY_Base_SysUserEntity,
    GettGY_Base_SysUserList,
    UpdatetGY_Base_SysUserEntity,
    DeletetGY_Base_SysUserEntity,
    UserResetPassword,
    UserChangePassword
} from '@/api/gy/base/sysuser'
import { toRaw } from 'vue'
import msg from '@/config/notify'
export const SysUser = {
    namespaced: true,
    state: {
        //用户列表
        UserList: [],
        //当前操作的用户对象
        UserEntity: null,
        //弹窗显示状态
        ShowDlg: false,
        //显示授权弹窗
        ShowAuth: false,
        //修改密码弹窗
        ShowChangePassword: false
    },
    mutations: {
        setUserList(state, data) {
            state.UserList = [...data]
        },
        setUserEntity(state, data) {
            state.UserEntity = data
        },
        setShowDlg(state, visible) {
            state.ShowDlg = visible
        },
        setShowAuth(state, visible) {
            state.ShowAuth = visible
        },
        setShowChangePassword(state, visible) {
            state.ShowChangePassword = visible
        }
    },
    actions: {
        //创建新用户的对象
        actCreateUser({ commit }, parm) {
            return new Promise((resolve, reject) => {
                CreatetGY_Base_SysUserEntity(parm).then(res => {
                    if (res.Code == 0) {
                        commit('setUserEntity', res.Data)
                        commit('setShowDlg', true)
                        resolve(res)
                    } else {
                        reject(res)
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        },
        //加载用户信息
        actLoadData({ commit }, parm) {
            return new Promise((resolve) => {
                GettGY_Base_SysUserList(parm).then(res => {
                    if (res.Code == 0) {
                        commit('setUserList', res.Data)
                        resolve(res)
                    } else {
                        msg.error(res.Message)
                    }
                })
            }).catch(err => {
                console.log(err)
            })

        },
        //保存用户信息
        actUpdateSysUser({ commit, state }, userInfo) {
            return new Promise((resolve) => {
                var obj = {}
                Object.assign(obj, userInfo)
                UpdatetGY_Base_SysUserEntity(obj).then(res => {
                    if (res.Code == 0) {
                        let list = toRaw(state.UserList)
                        let find = list.find(v => {
                            return v.UserID == res.Data.UserID
                        })
                        if (find) {
                            Object.assign(find, res.Data)
                        } else {
                            list.push(res.Data)
                        }
                        commit('setUserEntity', obj)
                        commit('setUserList', list)
                        commit('setShowDlg', false)
                        resolve(res)
                    } else {
                        msg.error(res.Message)
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        },
        //修改用户信息
        actEditSysUser({ commit }, userInfo) {
            commit('setUserEntity', userInfo)
            commit('setShowDlg', true)
        },
        //删除用户
        actDeleteSysUser({ commit, state }, userInfo) {
            return new Promise((resolve) => {
                DeletetGY_Base_SysUserEntity(userInfo).then(res => {
                    if (res.Code == 0) {
                        let list = toRaw(state.UserList)
                        let idx = list.findIndex(v => { return v.UserID == userInfo.UserID })
                        if (idx >= 0) {
                            list.splice(idx, 1)
                            commit('setUserList', list)
                        }
                        resolve(res)
                    } else {
                        msg.error(res.Message)
                    }
                })
            })
        },
        //重置登录密码
        actUserResetPassword(e, userInfo) {
            return new Promise((resolve, reject) => {
                UserResetPassword(userInfo).then(res => {
                    if (res.Code == 0) {
                        resolve(res)
                    } else {
                        msg.error(res.Message)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        //显示关闭用户授权弹窗
        actShowAuthDlg({ commit }, e) {
            commit('setUserEntity', e.userInfo)
            commit('setShowAuth', e.visible)
        },
        //用户修改密码
        actChangePassword({commit},e) {
            return new Promise((resolve) => {
                UserChangePassword(e).then(res => {
                    if (res.Code == 0) {
                        commit('setShowChangePassword',false)
                        resolve(res)
                    } else {
                        msg.error(res.error)
                        resolve(res)
                    }
                })
            })

        }
    }
}