import { axios } from '@/utils/request' //'../utils/request'

const api = {
    gyrole: '/tGY_Base_Role'
}

export default api

export function CreatetGY_Base_RoleEntity(parameter) {
    return axios({
        url: api.gyrole + '/CreatetGY_Base_RoleEntity',
        method: 'post',
        data: parameter
    })
}

export function GettGY_Base_RoleList(parameter) {
    return axios({
        url: api.gyrole + '/GettGY_Base_RoleList',
        method: 'post',
        data: parameter
    })
}

export function UpdatetGY_Base_RoleEntity(parameter) {
    return axios({
        url: api.gyrole + '/UpdatetGY_Base_RoleEntity',
        method: 'post',
        data: parameter
    })
}

export function DeletetGY_Base_RoleEntity(parameter) {
    return axios({
        url: api.gyrole + '/DeletetGY_Base_RoleEntity',
        method: 'post',
        data: parameter
    })
}